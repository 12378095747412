<template lang="">
  <div class="top-bar-buttons">
    <router-link
      to="/reminders"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-button
        :href="href"
        v-b-tooltip.hover
        title="Hatırlatmalar"
        @click="navigate"
        ><i class="flaticon-bell"></i
      ></b-button>
    </router-link>
    <router-link
      to="/properties/notes"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-button :href="href" @click="navigate" v-b-tooltip.hover title="Notlar"
        ><i class="flaticon-notes"></i
      ></b-button>
    </router-link>
    <router-link
      to="/manage/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-button
        :href="href"
        @click="navigate"
        v-b-tooltip.hover
        title="Adres Defteri"
        ><i class="flaticon-users-1"></i
      ></b-button>
    </router-link>
    <router-link
      to="/manage/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-button
        :href="href"
        @click="navigate"
        v-b-tooltip.hover title="SMS Gönder"
      ><i class="flaticon2-sms"></i
    ></b-button>
    </router-link>
    <router-link
      to="/manage/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <b-button
        :href="href"
        @click="navigate"
        v-b-tooltip.hover title="E-Posta Gönder"
      ><i class="flaticon-mail"></i
    ></b-button>
    </router-link>
    <router-link
      to="/manage/customer"
      v-slot="{ href, navigate, isActive, isExactActive }"
    > 
    <b-button
        :href="href"
        @click="navigate"
        v-b-tooltip.hover title="Harita"
      ><i class="flaticon-map-location"></i
    ></b-button>
    </router-link>
    
    <b-button href="https://bankamdan.com" target="_blank" v-b-tooltip.hover title="Siteye git"
      ><i class="flaticon2-website"></i
    ></b-button>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.top-bar-buttons {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding-right: 1rem;
  border-right: 1px solid $dveb-divider;
  i {
    color: #2196f3 !important;
    padding-right: 0;
  }
}
</style>
